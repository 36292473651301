import { useEffect, useState } from "react";
import type { SbBlockComponentProps } from "../types";
import { BlockTitle } from "./block-title";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  title?: string;
  htmlContent?: string | { text: string };
}>;

interface IframeMessage {
  type: string;
  height: number;
}

export function HtmlContentBlock({ blok }: Props) {
  const [iframeHeight, setIframeHeight] = useState("700px");

  useEffect(() => {
    // Event-Listener für die iframe Größenanpassung
    function handleIframeMessage(event: MessageEvent<IframeMessage>) {
      if (event.origin === "https://pts-group-karriere.factorialhr.de" && event.data.type === "resizeIframe") {
        setIframeHeight(`${event.data.height}px`);
      }
    }

    window.addEventListener("message", handleIframeMessage);

    // Cleanup beim Unmount
    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);

  return (
    <BlockWrapper blok={blok}>
      <BlockTitle title={blok.title} />
      <div className="w-full rounded-md overflow-hidden pb-8" style={{ height: iframeHeight }}>
        <iframe 
          frameBorder="0"
          height="100%"
          id="factorial-iframe" 
          src="https://pts-group-karriere.factorialhr.de/embed/jobs?embed=true&hideHeader=true&hideFooter=true" 
          style={{ border: 'none' }}
          title="Karriere-Portal"
          width="100%" 
        />
      </div>
    </BlockWrapper>
  );
} 