import { apiPlugin, storyblokInit, getStoryblokApi as getStoryblokApiBase } from "@storyblok/react";
import { GenericPageBlock } from "../blocks/pages/generic-page-block";
import LandingPageBlock from "../blocks/pages/landing-page-block";
import { SliderBlock } from "../blocks/slider-block";
import { PartnerLogosBlock } from "../blocks/partner-logos-block";
import { PartnersListPageBlock } from "../blocks/pages/partners-list-page-block";
import { LandingPagePartnersBlock } from "../blocks/landing-page-partners-block";
import { LandingPageCategoriesBlock } from "../blocks/landing-page-categories-block";
import { LandingPageSolutionsBlock } from "../blocks/landing-page-solutions-block";
import { SolutionSectorsListPageBlock } from "../blocks/pages/solution-sectors-list-page-block";
import { SolutionSectorPageBlock } from "../blocks/pages/solution-sector-page-block";
import { SolutionFamilyPageBlock } from "../blocks/pages/solution-family-page-block";
import { SolutionPageBlock } from "../blocks/pages/solution-page-block";
import { LandingPageNewsBlock } from "../blocks/landing-page-news-block";
import { NewsListPageBlock } from "../blocks/pages/news-list-page-block";
import { NewsPostPageBlock } from "../blocks/pages/news-post-page-block";
import { TechKnowledgePostPageBlock } from "../blocks/pages/tech-knowledge-post-page-block";
import { TechKnowledgeListPageBlock } from "../blocks/pages/tech-knowledge-list-page-block";
import { LandingPageTechKnowledgeBlock } from "../blocks/landing-page-tech-knowledge-block";
import { CategoryPageBlock } from "../blocks/pages/category-page-block";
import { CategoriesListPageBlock } from "../blocks/pages/categories-list-page-block";
import { ServicesListPageBlock } from "../blocks/pages/services-list-page-block";
import { ServicePageBlock } from "../blocks/pages/service-page-block";
import { LandingPageServicesBlock } from "../blocks/landing-page-services-block";
import { MarketsListPageBlock } from "../blocks/pages/markets-list-page-block";
import { MarketPageBlock } from "../blocks/pages/market-page-block";
import { CardGridBlock } from "../blocks/card-grid-block";
import { CardBlock } from "../blocks/card-block";
import { TableBlock } from "../blocks/table-block";
import { YoutubeVideoBlock } from "../blocks/youtube-video-block";
import { NativeVideoBlock } from "../blocks/native-video-block";
import { FileDownloadBoxBlock } from "../blocks/file-download-box-block";
import { ImageBlock } from "../blocks/image-block";
import { ImageGalleryBlock } from "../blocks/image-gallery-block";
import { ContactFormPageBlock } from "../blocks/pages/contact-form";
import { RichTextBlock } from "../blocks/rich-text-block";
import { PartnerPageBlock } from "../blocks/pages/partner-page-block";
import { GenericCenteredPageBlock } from "../blocks/pages/generic-centered-page-block";
import { EventsListPageBlock } from "../blocks/pages/events-list-page-block";
import { JobListBlock } from "../blocks/job-list-block";
import { ButtonBlock } from "../blocks/button-block";
import { AccordionBlock } from "../blocks/accordion-block";
import { ColumnsBlock } from "../blocks/columns-block";
import { RelatedStoriesBlock } from "../blocks/related-stories-block";
import { DividerBlock } from "../blocks/divider-block";
import { SpacerBlock } from "../blocks/spacer-block";
import { HtmlContentBlock } from "../blocks/html-content-block";

let isStoryblokInitialized = false;

export const initializeStoryblokApi = () => {
  if (isStoryblokInitialized) {
    return;
  }

  storyblokInit({
    accessToken: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
    use: [apiPlugin],
    components: {
      page: GenericPageBlock,
      landingPage: LandingPageBlock,
      slider: SliderBlock,
      partnerLogos: PartnerLogosBlock,
      partnersOverview: PartnersListPageBlock,
      partnersLandingPage: LandingPagePartnersBlock,
      CategoriesLandingPage: LandingPageCategoriesBlock,
      systemsLandingPage: LandingPageSolutionsBlock,
      systemsOverview: SolutionSectorsListPageBlock,
      systemSolution: SolutionSectorPageBlock,
      systemSolutionSubcategory: SolutionFamilyPageBlock,
      systemSolutionPage: SolutionPageBlock,
      htmlContent: HtmlContentBlock,
      NewsLandingPage: LandingPageNewsBlock,
      newsOverview: NewsListPageBlock,
      post: NewsPostPageBlock,
      technicalKnowledge: TechKnowledgePostPageBlock,
      technicalKnowledgeOverview: TechKnowledgeListPageBlock,
      technicalInfo: LandingPageTechKnowledgeBlock,
      category: CategoryPageBlock,
      categoryProduct: () => null, // supresses warnings during build
      categoriesOverview: CategoriesListPageBlock,
      servicesOverview: ServicesListPageBlock,
      servicePage: ServicePageBlock,
      servicesLandingPage: LandingPageServicesBlock,
      marketsOverview: MarketsListPageBlock,
      marketPage: MarketPageBlock,
      cardGrid: CardGridBlock,
      card: CardBlock,
      table: TableBlock,
      youtubeVideo: YoutubeVideoBlock,
      nativeVideo: NativeVideoBlock,
      fileDownloadBox: FileDownloadBoxBlock,
      image: ImageBlock,
      imageGallery: ImageGalleryBlock,
      contactFormPage: ContactFormPageBlock,
      richText: RichTextBlock,
      partner: PartnerPageBlock,
      centeredGenericPage: GenericCenteredPageBlock,
      eventsListPage: EventsListPageBlock,
      jobList: JobListBlock,
      button: ButtonBlock,
      accordion: AccordionBlock,
      columns: ColumnsBlock,
      relatedStories: RelatedStoriesBlock,
      divider: DividerBlock,
      spacer: SpacerBlock,
    },
  });

  isStoryblokInitialized = true;
};

/**
 * You should use this instead of `import { getStoryblokApi } from "@storyblok/react"`.
 *
 * Should only be initialized once, however after a rebuild / restart, we do not know
 * whether this will be done in _app.tsx or an API route.
 */
export const getStoryblokApi = () => {
  if (!isStoryblokInitialized) {
    initializeStoryblokApi();
  }

  return getStoryblokApiBase();
};
